.notification-icon {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  fill: #333;
}

.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: rgb(255, 87, 87);
  color: rgb(255, 243, 77);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  /* padding: 20px; */
  border-radius: 8px;
  width: 350px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
}

/* Dropdown Button */
.dropbtn {
  /* background-color: #04AA6D; */
  /* color: white; */
  /* padding: 16px; */
  /* font-size: 16px; */
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  max-height: 500;
  /* overflow: scroll; */
  
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  text-wrap: wrap;
  background-color: #f1f1f1;
  right: 0;
  /* min-width: 160px; */
  top: 15px;
  width: 350px;
  max-height: 500px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  margin-top: 5px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
/* .dropdown-content a:hover {
  background-color: #ddd;
} */

/* Show the dropdown menu on hover */
 

/* .dropdown-content a:hover {background-color: #ddd;} */

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}



.InfoCard-wrapper-note :hover{
  background-color: #ddd;
}

@media (max-width: 350px) {
  .invisible-350{
    display: none; /* Hide the text when width <= 1100px */
  }
}

@media (max-width: 1100px) {
  .notification-text {
    display: none; /* Hide the text when width <= 1100px */
  }
}

.InfoCard-wrapper-note {
  /* margin: 1rem 1rem 1rem 1rem; */
  display: flex;
  flex-direction: column;
  /* border-radius: 0.5rem; */
  overflow-x: clip;
  /* gap: 0.5rem; */
  /* max-width: 350px; */
  min-width: 350px;
  max-width: 350px;
  text-wrap: wrap;
}
.alarm_head{
  padding: 10px 10px;
  font-size: small;
  display: flex;
  justify-content: space-between;
}


.ic-line {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  text-wrap: wrap; 

}

.date-font{
  font-size: 12px;
}

.ic-edit-icon {
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.ic-inline-tag {
  font-weight: bold;
  font-size: 14px;
  width: 25%;
}

.ic-tag {
  font-weight: bold;
  font-size: 14px;
}

.ic-value {
  font-style: italic;
  font-size: 14px;
  gap: 1rem;
}

.ic-dropped-value {
  font-style: italic;
  font-size: 14px;
  gap: 1rem;
  margin-left: 5;
  margin-right: 10px;
}

.input-form {
  gap: 0.25rem;
}

.editInfoInput {
  background-color: var(--inputColor);
  border: none;
  outline: none;
  width: 90%;
  border-radius: 0.5rem;
  padding: 0.5rem;
}


.linkcss:link {
  color: green;
  background-color: transparent;
  text-decoration: none;
}
.linkcss:visited {
  color: rgb(63, 146, 255);
  background-color: transparent;
  text-decoration: none;
}
.linkcss:hover {
  color: rgb(45, 41, 255);
  background-color: transparent;
  text-decoration: underline;
}
.linkcss:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}
.text-container {
  max-width: 350px;
  /* margin: 20px auto; */
}
/* show details */

#text {
  
}

.collapsed {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Show 3 lines */
  max-height: 4.5em; /* Approximate height for 3 lines */
  overflow: hidden;
  display: -webkit-box;
  transition: max-height 1s ease;
  margin: 0;
}

.expanded {
  max-height: none; /* Show all text */
}

.show-more {
  background-color: #007bff00;
  color: rgba(94, 36, 255, 0.493);
  border: none;
  /* padding: 10px; */
  cursor: pointer;
  
  /* margin-top: 10px; */
}
.footer{
  margin-top: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between !important
  
}