.FollowCard {
    width: 100%;
    border-radius: 5rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    border: 2px solid var(--primaryColor);
    padding: 5px;
}

.FollowListHeader {
    /* display: flex; */
    /* gap: 1rem; */
    width: 100%;
    /* align-items: center; */
    justify-content: center;
    padding: 8px;
    font-style: italic;
}

.fc-list-container {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-height: 3500px;
    overflow-y: scroll;
}

.fc-empty-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.fc-empty1 {
    font-weight: bold;
    font-size: 14px;
}

.fc-empty2 {
    font-size: 12px;
}