.CreateItem {
    width: 100%;
}

.ci-main-card {
    background-color: var(--cardColor);
    max-width: 400px;
    border-radius: 1.5rem;
    padding-bottom: 1rem;
    margin: 0 auto;
}

.itemInput {
    border: none;
    outline: none;
    background-color: #a5d6a7;
    border-radius: 8px;
    padding: 10px;
}

.ci-input-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.itemIcon {
    color: #1b5e20;
}   

.itemTypeDropdownLabel {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.ci-imageIconContainer {
    color: var(--itemOrange);
    padding: 0px;
    margin: 0;
    
}

.ci-imageIconContainer:hover {
    cursor: pointer;
}

.previewImage {
    position: relative;
}

.previewImage>svg {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
}

.previewImage>img {
    width: 100%;
    max-height: 20rem;
    max-width: 50rem;
    object-fit: cover;
    border-radius: 0.5rem;
}

.imageUploadIcon {
    color: #1b5e20;
  }