.landing-header-container {
    width: 100%;
    /* border: 2px solid red; */
    /* background-color: white; */
}

.landing-backToTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
}

.landing-accented {
    color: var(--primaryColor);
}