.profile-left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    overflow: auto;
}

.Profile-card-collapsed {
    height: 1.3rem;
    overflow-y: hidden;
    scroll-behavior: unset;
}

.profile-expand-icon {
    transform: rotate(-90deg);
    margin-right: 5px;
    margin-left: auto;
    transition-duration: 0.5s;
}

.profile-contracted {
    transform: rotate(90deg);
}