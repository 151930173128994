.LandingResources {
    background-color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100vw;
    
}

.landing-resources-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.landing-resource {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
    border-radius: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;
    width: 250px;
}

.landing-resourceDesc {
    font-size: 12px;
}