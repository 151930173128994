.Relation{
    gap: 1rem;
    width: 90%;
    border: 1px solid var(--primaryColor);
    border-radius: 0.5rem;
    padding: 0.25rem;
}

.relation-item-image {
    width: 40px;
    max-height: 100px;
    border-radius: 0.2rem;
}

.relation-x {
    margin-right: 5px;
    margin-left: auto;
}

.relation-illustration-container {
    gap: 1rem;
    width: 100%;
}

.relation-text-container {
    width: 100%;
    font-size: 12px;
}