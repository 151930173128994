.FollowBar {
    border-radius: 1.5rem; 
    display: flex;
    flex-direction: row;
    overflow-x: clip;
    background: var(--cardColor);
    size: 30;
    width: 100%;
}

.fb-div {
    padding: 0.5rem;
    margin: 0;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
