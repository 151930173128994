.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s;
  }
  
  .back-to-top:hover {
    background-color: #0056b3;
  }
  
  .back-to-top:active {
    background-color: #004085;
  }

  #top {
    display: block; /* Ensures the anchor is rendered */
    height: 0;
    visibility: hidden; /* Keeps it invisible */
  }
  