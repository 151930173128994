.uv-profile-container {
    width: 100%;
    margin: 0.25rem;
}

.uv-profile-info {
    width: 100%;
    background-color: rgb(218, 218, 218);
    border-radius: 0.5rem;
    
}
.inside-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .stamps-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }
.uv-profile-header {
    width: 100%;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
}

.uv-profile-name {
    margin-left: 5px;
    margin-top: 5px;
    display:flex;
    flex-direction: column;

}

.uv-type-icon-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 5px;
    align-items: right;
    gap: 0.25rem;
    margin-top: 5px;
    margin-bottom: auto;
}

.uv-type-icon-container>div:nth-of-type(1) {
    margin-right: 0;
    margin-left: auto;
}


.uv-stats {
    gap: 0.5rem;
    background-color: rgb(170, 169, 169);
    border-radius: 0.5rem;
    padding: 0.2rem;
    margin-bottom: 5px;
}

.uv-info {
    gap: 0.2rem;
}

.uv-compass-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 10px;
}
.user-tagline {
    font-size: 0.8em;
    font-style: italic;
    color: #666;
    margin-left: 0;
  }
  
  .user-tagline::before {
    content: "“";
  }
  
  .user-tagline::after {
    content: "”";
  }