.ItemSearch {
    width: 260px;
    margin-left: 5px;
    margin-right: 5px;
    max-height: 200px;
    gap: 0.25rem;
    background-color: var(--cardColor);
    border-radius: 0.5rem;
    padding: 0.25rem;
    border: 2px solid var(--primaryColor);
}

.is-search {
    gap: 0.5rem;
    width: 100%;
    margin-bottom: 5px;
}

.is-input {
    width: 100%;
    font-size: 16px;
}

.is-instruction {
    font-size: 12px;
    gap: 1rem;
}