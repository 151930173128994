.iv-item-container {
    background-color: var(--itemCardColor);
    border-radius: 0.5rem;
    gap: 1rem;
    border: 2px solid var(--primaryColor);
}

.iv-top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.iv-name {
    width: 100%;
}

.iv-item-image {
    max-width: 200px;
}

.iv-stats {
    width: 100%;
    align-self: center;
    gap: 0.5rem;
    justify-content: center;
}

.iv-status-conflict {
    background-color: var(--conflictRed);
    border-radius: 0.25rem;
    gap: 0.25rem;
    padding: 5px;
}

.iv-status-clear {
    background-color: var(--affirmativeGreen);
    border-radius: 0.25rem;
    gap: 0.25rem;
    padding: 5px;
}

.iv-overall-stats {
    background-color: var(--primaryColor);
    border-radius: 0.25rem;
    gap: 0.25rem;
    padding: 5px;
}

.iv-specific-stats {
    background-color: var(--userTypeGroup);
    border-radius: 0.25rem;
    gap: 0.25rem;
    padding: 5px;
}

.iv-data-container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.iv-tabHeader {
    border-radius: 0.5rem;
    border: 2px solid var(--primaryColor);
    margin: 0.5rem;
}

.iv-th-top {
    background-color: var(--primaryColor);
    /* margin: 0.5rem; */
    /* border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; */
    padding: 5px; 
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.iv-th-text {
    font-style: italic;
    font-size: 14px;
}

.iv-th-rightIcons {
    margin-right: 5px;
    margin-left: auto;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.iv-th-tools {
    padding: 0.5rem;
    padding-bottom: 0rem;
}

.iv-th-arrow-container {
    text-align: center;
}

.iv-relational-container {
    padding: 5px;
}

.iv-tabWithNotification {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
}

.iv-suggestions-count {
    background-color: var(--affirmativeGreen);
    height: 0.75rem;
    width: 0.75rem;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 50%;
    padding: 0.25rem;
    font-size: 12px;
}

.iv-trigger-count {
    background-color: var(--conflictRed);
    height: 0.75rem;
    width: 0.75rem;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 50%;
    padding: 0.25rem;
    font-size: 12px;
}

.spin-animation {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  