.MainBar {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    overflow-x: clip;
    size: 30;
    gap: 0.25rem;
}

.mb-div {
    border-radius: 1.5rem; 
    padding: 0.5rem;
    margin: 0;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cardColor);
    gap: 0.25rem;
	font-family: Arial;
    font-style: italic;
    cursor: pointer;
}

.mb-div>span:nth-of-type(1) {
    font-size: 12px;
}

.mb-create-container {
    background-color: var(--userTypeAuthor);
    padding: 2px;
    border-radius: 0.5rem;
    gap: 0.25rem;
    display: flex;
    flex-wrap: wrap;
}
.activeCard {
    background: var(--primaryColor);
    color: white;
}

.mb-filter-icon {
    margin-right: 5px; 
    margin-left: auto;
    /* width: 10px; */
}

.mb-icon-container {
    padding: 0px;
    margin: 0px;
    /* I can't figure out what I need to do here to keep the nav buttons from shifting in size when clicked.  Should be seamless */
}