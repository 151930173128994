.SetCompass {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* width: 100%; */
    /* border: 2px solid red; */
}

.sc-set-values {
    background-color: var(--cardColor);
    border-radius: 0.5rem;
    padding: 0px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* width: 100%; */
}

.sc-title {
    gap: 0.5rem;
}

.sc-value-dropdown {
    width: 275px;
    height: 30px;
    border-radius: 0.25rem;
    background-color: var(--primaryColor);
    display: flex;
    flex-wrap: wrap;
}

.sc-value-dropdown>option {
    background-color: var(--primaryColor);
    display: flex;
    flex-wrap: wrap;
}   

.sc-input-form {
    background-color: var(--cardColor);
    gap: 1rem;
    border-radius: 0.5rem;
    padding: 10px;
    width: 100%;
}