.AddReferenceModal {
    min-width: 250px;
    max-width: 800px;
    max-height: 200px;
    /* border: 1px solid; */
    gap: 0.25rem;
    background-color: var(--cardColor);
    border-radius: 0.5rem;
    padding: 0.25rem;
}

.arm-content {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
}

.autocomplete-items {
    width: 250px;
    /* height: 40px; */

}

.arm-header {
    width: 100%;
    /* border: 1px solid; */
}

.arm-header-text {
    font-style: italic;
}

.arm-x {
    margin-left: auto;
    margin-right: 0.5rem;
}

.arm-search {
    width: 95%;
}
