.CreateDatum {
    width: 100%;
}
.cd-bg{
    background-color: rgb(247, 220, 179)
}
.cd-main-card {
    background-color: var(--cardColor);
    max-width: 800px;
    width: 90%;
    border-radius: 1.5rem;
    padding-bottom: 1rem;
    margin: 0 auto;
}

.cd-input {
    border: 2px solid var(--datumPurpleTransparent);
    outline: none;
    background-color: var(--datumPurpleTransparent);
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
    font-style: italic;
    width: 90%;
    position: relative;
    transition: border 0.5s;
}

.cd-input-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    width: 90%;
    gap: 1.5 rem;
}

.cd-input-span1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2em;
    width: 90%;
}

.cd-side-icon {
    width: 30px;
}

.cd-input-red {
    border: 2px solid var(--conflictRed);
    
}

.datumIcon {
    color: var(--datumPurple);
}   

.cd-arm-alert {
    display: none;
    width: 250px;
    background-color: var(--affirmativeGreen);
    border-radius: 0.25rem;
    font-size: 12px;
    padding: 0.5rem;
    text-align: center;
}

.cd-sources {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.cd-themes {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.25rem;
}

/* .cd-source-info {
    width: 100%;
    margin-left: 0;
    align-items: left;
} */

.cd-asm-container {
    background-color: var(--cardColor);
    border-radius: 0.25rem;
    padding: 0.2rem;
    gap: 0.25rem;
}

.cd-atm-container {
    background-color: var(--cardColor);
    border-radius: 0.25rem;
    padding: 0.2rem;
    gap: 0.25rem;
    /* I may not even need this */
    /* display: inline-flex; */
}

.cd-asm-text {
    font-size: 12px;
    font-weight: bold;
}

.cd-asm-link {
    font-size: 10px;
    font-style: italic;
}

.cd-asm-description {
    font-size: 12px;
}

.cd-delete {
    /* Something is happening with the display here that I don't understand.  It correctly hides the element upon load, but when I read the property in my JS logic it gives an empty string. */
    display: none;
    background-color: var(--conflictRed);
    height: 30px;
    width: 30px;
    z-index: 1000;
    border-radius: 0.25rem;
    position: relative;
}

.cd-atm-delete {
    /* Something is happening with the display here that I don't understand.  It correctly hides the element upon load, but when I read the property in my JS logic it gives an empty string. */
    display: none;
    background-color: var(--conflictRed);
    height: 20px;
    width: 20px;
    z-index: 1000;
    border-radius: 0.25rem;
    position: relative;
}





/* I can't get the following rules to load when inside the AddSourceModal.css file.
    None of the rules are applied.  I don't know why */
.AddSourceModal {
    width: 250px;
    max-height: 200px;
    gap: 0.25rem;
    background-color: var(--cardColor);
    border-radius: 0.5rem;
    padding: 0.25rem;
}

.asm-content {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
}


.asm-header {
    width: inherit;
}

.asm-header-text {
    font-style: italic;
}

.asm-x {
    margin-left: auto;
    margin-right: 0.5rem;
}

.cd-relations, .cd-references {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.cd-m-container {
    border-radius: 0.5rem;
    border: 1px solid var(--primaryColor);
    margin-left: 1rem;
    margin-right: 1rem;
}

.cd-db-btn{
    background-color: red;
}

.cd-validation {
    border-radius: 0.5rem;
    border: 2px solid var(--conflictRed);
    width: 80%;
    padding: 0.5rem;
}

.cd-submitButton {
    width: 8rem;
    height: 2rem;
    align-self: center;
}

.cd-btn-flex {
    flex:2 2 10%
}