.asm-content {
    gap: 0.2rem;
}

/* Container styles */
.AddSourceModal {
    max-width: 500px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-bottom: 50px;
    
}

/* Input and textarea styles */
.AppInput {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
}

.AppInput:focus {
    border-color: #66afe9;
    box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
    background-color: #fff;
}

/* Differentiating textarea */
textarea.AppInput {
    resize: vertical;
    /* min-height: 80px; */
}

/* Responsive design */
@media (max-width: 768px) {
    .AddSourceModal {
        padding: 15px;
    }

    .AppInput {
        padding: 10px;
    }
}