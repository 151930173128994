.InfoCard-wrapper {
    margin: 1rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow-x: clip;
    gap: 0.5rem;
}


.ic-line {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}


.ic-edit-icon {
    margin-right: 5px;
    margin-left: 5px;;
    cursor: pointer;
}

.ic-profile-data {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.ic-inline-tag {
    font-weight: bold;
    font-size: 14px;
    width: 25%;
}

.ic-tag {
    font-weight: bold;
    font-size: 14px;
}

.ic-value {
    font-style: italic;
    font-size: 14px;
    gap: 1rem;
}

.ic-dropped-value {
    font-style: italic;
    font-size: 14px;
    gap: 1rem;
    margin-left: 15px;
}

.input-form {
    gap: 0.25rem;
}

.editInfoInput {
    background-color: var(--inputColor);
    border: none;
    outline: none;
    width: 90%;
    border-radius: 0.5rem;
    padding: 0.5rem;
}