.LandingLogo {
    background-color: white;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.LandingLogo > img {
    max-width: 500px;
    
}