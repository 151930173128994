src/components/NotificationBell.css
.notification-bell {
    position: relative;
    cursor: pointer;
  }
  
  .bell-icon {
    font-size: 24px;
  }
  
  .bell-icon.unread {
    color: red;
  }
  
  .notification-list {
    display: none;
    position: absolute;
    top: 30px;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-width: 300px;
    z-index: 1000;
  }
  
  .notification-bell:hover .notification-list {
    display: block;
  }
  
  .notification-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .notification-item.unread {
    font-weight: bold;
  }
  
  .notification-board{
    margin: 2em;
    overflow: auto;
    /* padding: 10em; */
  }

  @media (max-width: 700px) {
    .invisible-700{
      display: none; /* Hide the text when width <= 1100px */
    }
  }
  .read-padding{
    /* padding: 0px 5px ; */
    width: 45px;
  }
  .read-panel{
    padding: 0px 10px ;
    /* width: 20px; */
  }

  .actions {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .note-row{
    display: flex;
  
  }

  @media (max-width: 700px) {
    .note-row {
      display: none; 
    }
  }

  .highlight {
    color: rgba(69, 135, 189, 0.884);;
    text-decoration: underline;
    cursor: pointer;
}
