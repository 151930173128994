.LandingAbout {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-left: 10px;
    margin-right: 10px;
}

.landing-aboutHeader {
    color: var(--primaryColor);
    border-bottom: 1px solid var(--primaryColor);
    text-indent: 20px;
    width: 100%;
}

.landing-aboutText {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    max-width: 600px;
    margin-left: 10px;
    margin-right: 10px;
}

.landing-aboutVideo {
    width: 90%;
    max-width: 600px;
    border-radius: 0.5rem;
    
}

.landing-subheading {
    border-bottom: 1px solid black;
    display: flex;
}

.landing-phaseTable {
    background-color: var(--primaryUltraLite);
}

.landing-phaseTable, th, td {
    border: 1px solid var(--primaryColor);
    border-collapse: collapse;
}

.landing-phaseTable-col1 {
    width: 50px;
    text-align: center;
    
}

.landing-phaseTable-col2 {
    width: auto;
    padding: 0.5rem;
}

.landing-phaseBody {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.landing-inset {
    padding: 0.5rem;
    border: 2px solid var(--primaryColor);
    border-radius: 0.25rem;
}

.landing-phaseFeaturesHeader {
    
}