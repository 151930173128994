.ProfileImages {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ProfileImages>img:nth-of-type(1) {
    width: 100%;
}

.ProfileImages>img:nth-of-type(2) {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: absolute;
    bottom: -3rem;
    box-shadow: var(--profileShadow);
}

.ProfileName {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    gap: 0.25rem;
}

.ProfileName>span:nth-of-type(1) {
    font-weight: bold;
    font-size: 16px;
}

.ProfileName>span:nth-of-type(2) {
    font-style: italic;
    font-size: 14px;
}

.ProfileName>span:nth-of-type(3) {

}

.FollowStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
}

.FollowStatus>div {
    display: flex;
    gap: 1rem;
    width: 80%;
    justify-content: space-around;
    align-items: center;
}

.follow {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: center;
    justify-content: center;
}

.follow>span:nth-of-type(1) {
    font-weight: bold;
}

.follow>span:nth-of-type(2) {
    color: var(--primaryColor);
    font-size: 18px;
}

.vl {
    height: 100%;
    border-left: 2px solid var(--hrColor);
}

.edit-link {
    font-weight: bold;
    color: var(--primaryColor);
    cursor: pointer;
    margin-bottom: 1rem;
    display:flex;
    justify-content: center;
}

.pc-stats {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.pc-stat {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
}

.pc-un-line {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
}

.refresh-spin {
    animation: spin 1s linear infinite;
}

