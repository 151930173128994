.UserDetail {
    border-radius: 0.25rem;
    border: 2px solid var(--secondaryColor);
    padding: 5px;
    gap: 0.5rem;
}
.UserName {
    cursor: pointer;
}
.ud-follow-border {
    border: 2px solid var(--affirmativeGreen) !important;
}

.ud-header {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 0.5rem;
}

.ud-user-img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.ud-headerDetails {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.ud-typeIcon {
    margin-right: 5px;
    margin-left: auto;
}

.ud-body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    
}

.ud-body-container>span:nth-of-type(1) {
    font-style: italic;
    font-size: 12px;
}

.ud-statement-container {
    overflow-y: hidden;
    transition-duration: 0.5s;
}

.ud-statement-mask {
    height: 60px;
    /* color: black; */
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, 
    from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    transition-duration: 0.5s;
}

/* .ud-statement.container::before {
  position: absolute;
  content: '';
  background: var(--affirmativeGreen);
  height: 60px;
  width: 100%;
  pointer-events: none;
} */

.ud-statement-expand-bar {
    background-color: var(--secondaryColor);
    width: 100%;
    border-radius: 0.25rem;
    justify-content: center;
    text-align: center;
}

.ud-values {
    display: flex;
    flex-direction: column;
    
    gap: 0.25rem;
}
