.modal-overlay{
    display: none;
}

.modal-content{
    min-width: 600px;
    width: 35%;
    /* z-index: 1000; */
    background-color: rgb(247, 220, 179) !important
}
.cd-bg{
    background-color: rgb(247, 220, 179)
}