:root {
    --page-background: #dddddd;
    --black: #242d49;
    --white: #ffffff;
    --inputColor: rgba(40,52,62,0.07);
    --primaryColor: rgba(69, 135, 189, 0.884);
    --primaryLite: rgba(122, 191, 248, 0.884);
    --primaryUltraLite: rgba(221, 236, 250, 0.884);
    --secondaryColor: rgba(174, 180, 184, 0.884);
    --secondaryColorDark: rgba(87, 91, 94, 0.884);
    --profileShadow: 0px, 4px, 17px, 2px rgba(0,0,0,0.25);
    --hrColor: #a8a6a6;
    --cardColor: rgba(255, 255, 255, 0.64);
    --itemCardColor: rgba(181, 214, 252, 0.527);
    --datumCardColor: rgba(188, 231, 248, 0.527);
    --stampedDatumCardColor: rgb(188, 231, 248);
    --viewedDatumCardColor: rgb(191, 193, 194);
    --conflictRed: rgba(192, 91, 91, 1);
    --conflictLightRed: rgb(223, 160, 160);
    --alertYellow: rgb(243, 237, 188);
    --link: rgb(75, 128, 224);
    --affirmativeGreen: rgb(142, 207, 126);
    --affirmativeGreenDark: rgb(113, 151, 103);
    --casualGreen: rgb(169, 202, 160);
    --datumPurple: rgb(153, 107, 134);
    --datumPurpleTransparent: rgba(153, 107, 134, 0.25);
    --itemOrange: rgb(197, 135, 42);
    --itemOrangeTransparent: rgba(197, 135, 42, 0.5);
    --userTypeGeneral: rgb(0, 0, 255);
    --userTypeAuthor: rgb(0, 128, 0);
    --userTypeGroup: rgb(255, 165, 0);
    --itemTypeProduct: rgb(73, 211, 192);
    --itemTypeOrganization: rgb(143, 52, 87);
    --itemTypeIndividual: rgb(131, 89, 41);
    --itemTypeLocation: rgb(26, 85, 26);
    --itemTypeCategory: rgb(25, 92, 97);
}   

a {
    color: inherit;
    text-decoration: none;
}

hr {
    width: 85%;
    border: 1px solid var(--hrColor);
}

.App {
    position: relative; 
    overflow-x: hidden;
    /* overflow-y: hidden; */
    color: var(--black);
    background: var(--page-background);
    padding: 1rem 1rem;
    gap: 1rem;
    width: 100%;
    /* max-width: 1250px; */
}

.HeaderGrid {
    display: grid;
    grid-template-rows: 3rem auto;
    gap: 1rem;
    
}

.BodyGrid {
    position: relative; 
    display: grid;
    grid-template-columns: 18rem auto 16rem;
    gap: 1rem;   
}

.clickable {
    cursor: pointer;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: var(--primaryColor);
    border: none;
    border-radius: 0.25rem;
    transition: all 100ms ease-out;
    border: 2px solid var(--primaryColor);
}

.button:hover {
    cursor: pointer;
    color: var(--secondaryColor);
    background: transparent;
    border: 2px solid var(--secondaryColor);
}

.button-red {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: var(--conflictRed);
    border: none;
    border-radius: 0.25rem;
    transition: all 100ms ease-out;
    border: 2px solid var(--conflictRed);
}

.button-red:hover {
    cursor: pointer;
    color: var(--conflictLightRed);
    background: transparent;
    border: 2px solid var(--conflictLightRed);
}

.grayButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: var(--secondaryColor);
    border: none;
    border-radius: 0.5rem;
    transition: all 100ms ease-out;
    border: 2px solid var(--secondaryColor);
}

.button:disabled {
    background: silver;
    pointer-events: none;
}

/* What is this webkit - dive in! */
/* Also, I might be crazy, but it looks like the scrollbar actually appears when I add this "display: none" line */
::-webkit-scrollbar {
    display: none;
}

.AppGeneralCard {
    border-radius: 1.5rem; 
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1rem;
    overflow-x: clip;
    background: var(--cardColor);
    width: 100%;
}

.AppGeneralEditLine {
    font-weight: bold;
    color: var(--primaryColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
}

.AppGeneralEditLine:hover {
    cursor: pointer;
}

.FlexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.FlexColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AppInput {
    display: flex;
    background-color: var(--inputColor);
    border-radius: 10px;
    padding: 5px;background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
}

.GeneralTabs {
    width: 100%;
}

.General-hidden {
    display: none !important;
}

.General-input {
    background-color: var(--inputColor);
    border: none;
    outline: none;
    width: 90%;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.General-exp-arrow {
    transform: rotate(-90deg);
    margin-right: 5px;
    margin-left: auto;
    transition-duration: 0.5s;
}

.General-exp-arrow-con {
    transform: rotate(90deg);
}

.General-value-dropdown {
    width: 275px;
    height: 30px;
    border-radius: 0.25rem;
    background-color: var(--primaryColor);
    display: flex;
    flex-wrap: wrap;
}

.General-value-dropdown>option {
    background-color: var(--primaryColor);
    display: flex;
    flex-wrap: wrap;
} 

.General-filter-bar {
    background-color: var(--cardColor);
    /* width: 100%; */
    border-radius: 0.5rem;
    padding: 5px;

}

.General-filter-form {
    gap: 0.5rem;
    width: 100%;
}

.General-filter-key {
    font-size: 12px;
    width: 70px;
}

.General-filter-long-key {
    font-size: 12px;
    width: 100px;
}

.General-filter-selected {
    background-color: var(--cardColor);
    border: 2px solid var(--primaryColor);
    padding: 5px;
    border-radius: 0.5rem;
}

.General-validationError {
    color: var(--conflictRed);
    font-size: 12px;
}

.General-validationSuccess {
    color: var(--affirmativeGreenDark);
    font-size: 12px;
}

.General-loading-circle {
    border: 2px solid #d8d7d7;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  } 

/* For scroll-to anchors Element tag placement offset */
.anchor-position-offset {
    position: absolute;
    bottom: 60px;
    /* display: none; */
}

@media (max-width: 1000px) {
    .BodyGrid {
        position: relative; 
        display: inline-grid;
        grid-template-columns: 18rem auto;
        grid-template-rows: auto 1fr;
        gap: 1rem;
    }

    .Main {
        grid-column: 2;
        grid-row: 1 / span 2;
        
    }
}

@media (max-width: 700px) {
    .BodyGrid {
        position: relative; 
        display: flex;
        flex-direction: column;
        /* gap: 5rem; */
        scroll-padding-top: 40px;
    }

}