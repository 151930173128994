.CategoryBrowse {
}

.catNavPath {
    gap: 0.5rem; 
}

.cb-nav {
    background-color: var(--primaryLite);
    border-radius: 1.5rem;
    padding: 0.5rem;
    min-width: 25px;
    justify-content: center;
}
