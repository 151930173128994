.Datum {
    border-radius: 0.75rem; 
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0rem;
    overflow-x: clip;
    background: var(--datumCardColor);
    border: 1px solid var(--primaryLite);
}
.Datum-Draft{
    color: #562e8b;
    border-radius: 0.75rem; 
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0rem;
    overflow-x: clip;
    /* background: rgba(255 228 177 /50%); */
    background-color: hsla(12, 100%, 50%, 0.212);
    border: 1px solid rgb(248 122 122 / 88%);;
}

.stampedDatum {
    background: var(--stampedDatumCardColor);
    /* border: 1px solid var(--primaryColor); */
}

.viewedDatum {
    border: 2px solid var(--primaryColor);
    /* I would like to darken the background on viewed data, but it causes other issues.  Come back to this */
    /* background: var(--viewedDatumCardColor) */
}

.itembar{
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.1 rem;
    gap: 1em;
}

.item{
    align-items: center;
    /* background-color: beige; */
    width: 7em;
    border-radius: 1em;
    padding: 0.3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #ccc; /* Optional: for visual reference */
    cursor: pointer; /* Change cursor to pointer on hover */
    background-color: #f9f9f9; /* Optional: background color */
}
.item:hover {
    background-color: #e0e0e0; /* Optional: hover effect */
}

.triggeredDatum {
    background: var(--conflictLightRed);
}

.generalBar {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 0.5rem;
}


/* top */
.datumBody {
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    cursor: pointer;
}

.datumText {
    /* font-style: italic; */
    font-size: medium;
    /* border: 1px solid red; */
    width: 100%;
}

.dl-relations {
    gap: 0.5rem;
    font-size: medium;
    width: 100%;
    align-items: left;
}

.dl-relationRow {
    color: rgb(194, 127, 2);
    /* border: 2px solid blue; */
    width: 100%;
    gap: 1rem;
    justify-items:start;
}

.dl-endIcon {
    font-size: 30px;
    /* size: 6rem; */
    /* border: 2px dotted orange; */

}

.dl-vl {
    border-left: 4px solid var(--primaryColor);
    height: 4rem;
    border-radius: 0.5rem;
}


/* middle */
.Datum>hr {
    margin-left: 0.5rem;
    width: 95%;
    border: 1px solid var(--hrColor);

}


/* bottom */
.bottom-bar {
    width: 100%;
}

.datumDetails {
    margin: 0.5rem;
    font-size: small;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.datumAuthor {
    display: flex;
    align-items: center;
}

.authorBar>img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.conflictIcon {
    color: var(--conflictRed);
}

.themes {
    font-style: italic;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.themes>a:link {
    color: var(--link); 
    
}

.mark-buttons {
    margin-left: auto;
    margin-right: 1rem;
    gap: 0.5rem;
}

.mark-buttons > * {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
}

/* .mark-buttons > *:disabled {
    cursor:not-allowed
} */

.button-marked {
    background: gray;
    color: black;
}



.button-unmarked {
    background: var(--primaryColor);
    color: white;
}

.dl-followed-groups {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 5px;
    margin-bottom: 5px;
    gap: 0.25rem;
}

.dl-followed-groups>img {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    
}