.DataFeed {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: auto;
    cursor: pointer;
}


.df-themes-container {
    width: 200px;
    margin-left: 70px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}


.df-input-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2em;
    width: 90%;
}

.df-Button {
    width: 8rem;
    height: 2rem;
    align-self: center;
}

.df-selected-theme {
    background-color: var(--affirmativeGreen);
    /* width: 200px; */
    padding: 5px;
    border-radius: 0.25rem;
}

.small-label {
    font-size: 12px;
  }