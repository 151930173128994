.Compass-wrapper {
    margin: 0.7rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow-x: clip;
    gap: 0.5rem;
}

.dropdown-header{
    min-height: 50px;
}

.compassData {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 12px;
}

.compass-settings-icon {
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
}
.dropdown-bar{
    height: 50px;
}
