.relation-item-delete {
  background: linear-gradient(to right, var(--conflictRed), rgba(255, 255, 255, 0));
  height: 100%;
  width: 0;
  z-index: 1000;
  border-radius: 0.25rem;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  transition: all 0.25s;
  overflow: clip;
}
.relation-item-show-delete {
  width: 80px;
  transition: all 0.25s;
  padding-left: 5px;
}

.relation-item-view {
  background: linear-gradient(to left, var(--primaryColor), rgba(255, 255, 255, 0));
  height: 100%;
  width: 0;
  z-index: 1000;
  border-radius: 0.25rem;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  transition: all 0.25s;
  overflow: clip;
}


.relation-item-show-view {
  width: 80px;
  transition: all 0.25s;
  padding-right: 5px;
}