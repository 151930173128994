.Category {
    /* width: 100%; */
    min-width: 150px;
    max-width: 600px;
    min-height: 40px;
    gap: 0.25rem;
    position: relative;
    z-index: 0;
    border-radius: 0.5rem;
    padding: 5px;
    border: 1px solid var(--datumCardColor);
    background-color: var(--datumCardColor);
    overflow: hidden;
}