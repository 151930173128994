.LandingConnect {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    margin-left: 10px;
    margin-right: 10px;
    width: 100vw;
    
}

.landing-connect-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    
}

.landing-connect-socialMedia {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.landing-connect-icon {
    border: 2px solid var(--primaryColor);
    border-radius: 0.25rem;
    width: 30px;
    height: 30px;
    align-content: center;
    background-color: var(--primaryColor);
}