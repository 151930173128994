.RelationalItem {
    gap: 0.5rem;
    align-content: center;
    width: 100%;
}

.ri-item-bar {
    gap: 0.5rem;
    align-content: center;
    /* border: 2px solid black; */
    width: inherit;
    justify-content: space-around;
}

.ri-pre-icons {
    /* border: 2px solid red; */
    gap: 0.2rem;
}

.ri-post-icons {
    /* border: 2px solid red; */
}

.ri-icon-group {
    border: 2px solid var(--primaryColor);
    border-radius: 0.25rem;
    height: 20px;
    width: 40px;
    transition-duration: 0.5s;
}

.ri-button-selected {
    background-color: var(--primaryColor);
}

.ri-arrow {
    transition-duration: 0.5s;
}

.ri-rotated {
    transform: rotate(90deg);
}

.ri-item-container {
    width: 250px;
    min-height: 40px;
    gap: 0.25rem;
    position: relative;
    z-index: 0;
    border-radius: 0.5rem;
    padding: 0.2rem;
    cursor: pointer;
}

.ri-triggered {
    background-color: var(--conflictRed);
    border: 2px solid var(--conflictRed);
}

.ri-normal {
    border: 2px solid var(--primaryColor);
}

.ri-stats-bar {
    width: inherit;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.ri-stat {
    gap: 0.1rem;
}

.ri-transferrence {
    background-color: var(--casualGreen);
}

.ri-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}