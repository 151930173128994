.LandingStats {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ls-stat-type-container {
    width: 100vw;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
    gap: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.ls-broad-container {
    background-color: var(--secondaryColor);
}

.ls-users-container {
    background-color: var(--primaryColor);
}

.ls-content-container {
    background-color: var(--secondaryColor);
}

.ls-stat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    max-width: 350px;
    background-color: var(--cardColor);
    border-radius: 0.5rem;
    padding: 1rem;
    height: 100%;
    margin: 1rem;
    gap: 1rem;
}

.ls-stat-title {
    gap: 0.5rem;
}

.ls-stat-value {
    width: 100%;
    font-size: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ls-stat-description {
    font-size: 12px;
    text-align: center;
}

.ls-launchImg {
    height: 120px;
}

.ls-topicsTable-key {
    font-size: 12px;
    gap: 0.25rem;
    background-color: var(--primaryUltraLite);
    border: 1px solid var(--primaryLite);
    border-radius: 0.25rem;
    padding: 0.25rem;
}

.ls-topicsTable {
    border-radius: 0.5rem;
    width: 100%;
    justify-content: center;
}

.ls-topicsTable-topic {
    /* width: 100%; */
    font-size: 18px;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    padding: 0.25rem;
    background-color: var(--primaryLite);
    
}

.ls-topicsTable-iconCell {
    text-align: center;
    vertical-align: middle;
    width: 30px;
}

.ls-topicsTable-future {
    color: var(--secondaryColorDark);
}

.ls-topicsTable-building {
    color: var(--primaryColor);
}

.ls-topicsTable-launched {
    color: var(--affirmativeGreenDark);
}

.ls-topicsTable-arrowCell {
    margin-right: 0;
    margin-left: auto;   
}

.ls-topicsTable-details {
    font-size: 14px;
    /* width: 100%; */
    border: 2px solid var(--primaryLite);
    background-color: var(--primaryUltraLite);
    padding: 0.5rem;
    /* border-radius: 0.5rem; */
    gap: 0.5rem;
}

.ls-subtopic {
    width: 80%;
    border-radius: 0.25rem;
    border: 1px solid var(--primaryLite);
}

.landing-values-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid black;
}

.landing-values-core {
    background-color: var(--primaryLite);
    border-bottom: 1px solid black;
    padding: 0.25rem;
}

.landing-values-value {
    padding: 0.25rem;
}