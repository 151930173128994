.Reference {
    /* width: 100%; */
    min-width: 150px;
    max-width: 600px;
    min-height: 40px;
    gap: 0.25rem;
    position: relative;
    z-index: 0;
    border-radius: 0.5rem;
    padding: 5px;
    border: 1px solid var(--primaryColor);
    overflow: hidden;
}

.green-reference {
    background-color: var(--affirmativeGreen);
}

.normal-reference {
    /* background-color: var(--primaryColor); */
    border: 2px solid var(--primaryColor);
}

.arm-link-item-delete {
    background: linear-gradient(to right, var(--conflictRed), rgba(255, 255, 255, 0));
    height: 100%;
    width: 0;
    z-index: 1000;
    border-radius: 0.25rem;
    position: absolute;
    /* left: -60px; */
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.25s;
    overflow: clip;
}
.arm-link-item-view {
    background: linear-gradient(to left, var(--primaryLite), rgba(255, 255, 255, 0));
    height: 100%;
    width: 0;
    z-index: 1000;
    border-radius: 0.25rem;
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    transition: all 0.25s;
    overflow: clip;
}
.arm-link-item-show-delete {
    width: 80px;
    transition: all 0.25s;
    padding-left: 5px;
}
.arm-link-item-show-view {
    width: 80px;
    transition: all 0.25s;
    padding-right: 5px;
}

.arm-link-item-imageContainer>img {
    width: 40px;
    max-height: 100px;
    border-radius: 0.2rem;
}

.arm-link-item-infoContainer {
    width: 100%;
}

.arm-link-item-nameBar {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    display: grid;
    grid-template-columns: auto 20px;
}

.arm-link-item-itemName {
    font-size: 16px;
    margin-left: 0;
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
}

.arm-link-item-itemIcon {
    margin-left: auto;
    margin-right: 5px;
}

.arm-link-item-itemLinks {
    width: 100%;
    font-size: 10px;
    margin-left: 0;
    gap: 0.1rem;
}

.item-icon-product {
    color: var(--itemTypeProduct);
}

.item-icon-organization {
    color: var(--itemTypeOrganization);
}

.item-icon-individual {
    color: var(--itemTypeIndividual);
}

.item-icon-location {
    color: var(--itemTypeLocation);
}

.item-icon-category {
    color: var(--itemTypeCategory);
}




.arm-link-item-adoption {
    margin-left: auto;
    margin-right: 2px;
    gap: 0.2rem;
}

.arm-link-item-userImage>img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

/* delete this when removing demo */
.arm-demo-error {
    position: fixed;
    color: var(--conflictRed);
    font-size: 12px;
    width: 250px;
    background-color: var(--white);
    border-radius: 0.25rem;
}