.User {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.User>div {
    display:flex;
    gap: 10px;
}

.UserImg {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
}

.UserName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.UserName>span:nth-of-type(1) {
    font-weight: bold;
}

.UserName>span:nth-of-type(2) {
    font-size: 10px;
}

.User-button {
    height: 2rem;
    padding-left: 20px;
    padding-right: 20px;
}

.user-icons {
    
}

.user_{
    margin-left: 20px;
}