.UserFeed {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--cardColor);
    /* width: 100%; */
    border-radius: 0.5rem;
    padding: 5px;
}

