.AddRelationModal {
    min-width: 250px;
    max-width: 800px;
    max-height: 200px;
    /* border: 1px solid; */
    gap: 0.25rem;
    background-color: var(--cardColor);
    border-radius: 0.5rem;
    padding: 0.25rem;
}

.arelm-content {
    font-style: italic;
    font-size: 12px;
}

.arelm-search {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.arelm-dropdown {
    border-radius: 0.25rem;
    background-color: var(--primaryColor);
    display: flex;
    flex-wrap: wrap;
    display: none;
}