.Logs {
    overflow: scroll;
}

.logs-entry {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    border-bottom: 1px solid black;
}

.logs-alert {
    background-color: var(--alertYellow);
}

.logs-error {
    background-color: var(--conflictLightRed);
}

.logs-date {
    width: 175px;
}

.logs-activity {
    width: 150px;
}

.logs-dataObj {
    display: flex;
    flex-direction: column;
    
}

.logs-dataObjLine {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
}
 
.logs-dataObjKey {
    width: 150px;
}

.logs-dataObjValue {
    width: 250px;
    overflow-x: hidden;
}

.logs-respObjKey {
    width: 60px;
}