.Header {
    border-radius: 1.5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: var(--primaryUltraLite);
    /* padding-top: 1rem;
    padding-left: 1rem; */
    /* color: var(--primaryColor); */
    width: 100%;
    /* max-width: 90vw; */
    z-index: 99;
    margin-right: 30px;
    /* position: fixed; */
}

.LandingHeader {
    width: 100%;
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    border-bottom: 1px solid var(--primaryColor);
    /* background-color: white; */
}


.h-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 2rem;
}

.h-name {
    font-size: 12px;
    color: var(--primaryColor);
    font-style: italic;
    gap: 0.25rem;
    max-width: 150px;
    overflow-x: hidden;
}

.h-nav-icons {
    display: none;
}

.h-landing-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    /* background-color: white; */
    border-radius: 0.25rem;
}

.h-page-span {
    gap: 0.25rem;
}

.h-adminButton {
    background-color: rgb(171, 228, 209);
    border-radius: 0.25rem;
    padding: 0.25rem;
}

.h-menu, .h-landingMenu {
    gap: 1rem;
    display: flex;
    padding-right: 1rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.headerLogo {
    width: 3rem;
    height: 3rem;
}

.menu-icon-default, .menu-icon-landing {
    display: none;
    color: var(--primaryColor);
}

.userTypeIcon {
    display: flex;
    height: 80%;
    padding: 0.3rem;
    border-radius: 0.2rem;
    justify-self: center;
}

.userTypeGeneral {
    color: white;
    background-color: var(--userTypeGeneral);
}

.userTypeAuthor {
    color: white;
    background-color: var(--userTypeAuthor);
}

.userTypeGroup {
    color: white;
    background-color: var(--userTypeGroup);
}

@media (max-width: 700px) {
    
    .Header {
        position: fixed;
        width: 93%;
    }
    
    .h-nav-icons {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-right: 10px;
        margin-left: auto;
    }

    .menu-icon-default {
        display: block;
        margin-right: 10px;
    }
    
    .h-menu {
        z-index: 99;
        color: black;
        position: absolute;
        top: 1rem;
        right: 4rem;
        background: white;
        border: 2px solid var(--primaryColor);
        flex-direction: column;
        font-weight: 500;
        gap: 1rem;
        padding: 1rem;
        border-radius: 10px;
        align-items: flex-start;
        font-size: 14px;
    }
}

@media (max-width: 1150px) {

    .menu-icon-landing {
        display: block;
        margin-right: 10px;
    }
    
    .h-landingMenu {
        z-index: 99;
        color: black;
        position: absolute;
        top: 1rem;
        right: 4rem;
        background: white;
        border: 2px solid var(--primaryColor);
        flex-direction: column;
        font-weight: 500;
        gap: 1rem;
        padding: 1rem;
        border-radius: 10px;
        align-items: flex-start;
        font-size: 14px;
    }
}

@media (max-width: 575px) {
    .h-landing-nav {
        display: none;
    }
}