.Tool-Container {
  display: block;
}

.product-cell,
.category-cell,
.madeby-cell,
.soldby-cell,
.substitute-cell {
  min-width: 250px;
  gap: 0.5rem;
}

.tool-sheet-title {
  font-size: 24px;
  width: auto;
  background-color: var(--primaryUltraLite);
  border: 1px solid var(--primaryColor);
  border-radius: 0.5rem;
  padding: 5px;
}

.tool-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tool-headerButton-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tool-themeSelector-container {
  margin-right: 0px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: auto;
  justify-content: right;
}

.tool-main-table {
  border: 1px solid var(--primaryColor);
  border-radius: 0.5rem; 
}

.tool-sheetsDropdown {
  font-size: 14px !important;
  padding: 5px;
}

.tool-simpleBar-inside-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.table-container {
  position: relative;
  width: calc(100vw - 48px);
  overflow-x: auto;
}

.table-header {
  min-width: 250px;
}

.tool-itemOption-container {
  width: 100%;
}

.tool-cellButton-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: space-evenly;
  gap: 0.5rem;
}

.tool-buttons {
  padding: 5px;
  width: 100%;
  font-size: 14px;
}

.tool-bottom {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 10px;
}

/* Other Tool Components */
.tool-datumView-container {
  border: 1px solid var(--primaryColor);
  border-radius: 0.5rem;
  padding: 5px;
  background-color: var(--primaryUltraLite);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.tool-datumView-relation {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
  justify-content: center;
}

.tool-datumView-stat {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
}



.Small-Screen {
  display: none;
}
.flex {
  display: flex;
}
.visible {
  display: block;
}
.invisible {
  display: none;
}
.hidden {
  display: none;
}

.flex-col {
  flex-direction:column;
}
.grid {
  display: grid;
}
.grid-cols-5 {
  grid-template-columns: repeat(5, 1fr);
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.left-0 {
  left: 0px;
}
.top-0 {
  top: 0px;
}
.h-4 {
  height: 2rem;
}
.w-0 {
  width: 0px
}
.w-4 {
  width: 2rem;
}
.w-full {
  width: 100%;
}

.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-5 {
  gap: 1.25rem;
}

.text-small {
  font-size: 0.75rem;
}
.text-middle {
  font-size: 1rem;
}
.text-button {
  font-size: 1rem;
}
.text-orange-500 {
  color: #FFCC00;
}
.text-center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}

.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mr-2 {
  margin-right: 0.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; 
}
.pr-2 {
  padding-right: 0.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.p-1 {
  padding-left: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.25rem;
}
.p-2 {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
}
.items-center {
  align-items: center;
}
.justify-end {
  justify-content: end;
}
.justify-center {
  justify-content: center;
}
.ant-dropdown {
  font-family: Arial;
  font-size: 1rem;
}

.bg-white {
  background-color: white;
}

.bg-danger {
  background-color: #d9534f;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.border-b {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.border-r {
  border-right-width: 1px;
  border-right-style: solid;
}
.border-t-2 {
  border-top-width: 2px;
  border-top-style: solid;
}
.border-solid {
  border-style: solid;
}
.border-white {
  border-color: white;
}

.rounded-sm {
  border-radius: 0.25rem;
}
.border-error {
  border-color: #d9534f;
}
.border-success {
  border-color: #5cb85c;
}
.border-primary {
  border-color: #0275d8;
}
.border-gray {
  border-color: #998F85;
}

.remove-transition {
  transition: all 0.15s ease;
  background: linear-gradient(to right, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 0) 100%);
}

.similarContainer {
  position: absolute;
  top: 35px;
  z-index: 50;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 4px 10px rgba(200, 200, 200, 0.5);
  border: 0px solid transparent;
  border-radius: 0.5rem;
  width: 100%;
}

.shadowGrayLarge {
  box-shadow: 0 4px 10px rgba(200, 200, 200, 0.5);
}
.shadowGrayLarge:hover {
  box-shadow: 0 6px 10px rgba(200, 200, 200, 0.5);
}
.user-defined-similarContainer {
  padding: 0.5rem; 
  background-color: #c6f6d5;
  box-shadow: 0 4px 10px rgba(0, 255, 255, 0.5);
}

.shadowCyan {
  box-shadow: 0 4px 10px rgba(0, 255, 255, 0.5); /* Cyan color with 50% opacity */
}
.relation-id {
  word-wrap: break-word;
}
@media (max-width: 999px) {
  .Tool-Container {
      display: none; /* or use visibility: hidden; */
  }
  .Small-Screen {
    display: block;
  }
}