.AddThemeModal {
    width: 250px;
    max-height: 200px;
    /* border: 1px solid; */
    gap: 0.25rem;
    background-color: var(--cardColor);
    border-radius: 0.5rem;
    padding: 0.25rem;
}

.autocomplete-items {
    width: 250px;
    /* height: 40px; */

}

.atm-header {
    width: inherit;
    /* border: 1px solid; */
}

.atm-header-text {
    font-style: italic;
}

.atm-x {
    margin-left: auto;
    margin-right: 0.5rem;
}

.atm-search {
    width: 95%;
}

.atm-submit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primaryColor);
    border: 2px solid var(--primaryColor);
    border-radius: 5px;
    padding: 4px;
    color: white;
    margin-left: auto;
    margin-right: 0;
}

.atm-content {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    width: 100%;
}

.atm-result {
    background-color: var(--secondaryColor);
    padding: 0.25rem;
    border-radius: 0.25rem;
}

.atm-result-theme {
    font-size: 14px;
}

.atm-result-stats {
    font-size: 12px;
}

.atm-search-bar {
    gap: 0.5rem;
}