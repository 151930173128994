.Auth {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content:center;
    height: 100vh;
    gap: 4rem;
    /* position: relative; */
}

@media (max-width: 625px) {
    .Auth {
        display: flex;
        flex-direction: column;

    }
}

.Auth > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-left {
    flex-direction: column;
    /* gap: 1rem; */
}

.auth-webname {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-webname>div:nth-of-type(1) {
    font-size: 30px;
}

.auth-logo {
    width: 6rem;
    height: 6rem;
}

.input-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.signupInput {
    border: none;
    outline: none;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
}

.loginSignup {
    font-size: 10px;
    cursor: pointer;
}

.signupButton {
    width: 6rem;
    height: 2rem;
    align-self: center;
}

.validationError {
    /* All references to this rule need to be switched over to General-validationError */
    color: var(--conflictRed);
    font-size: 12px;
    /* align-self: flex-end;
    margin-right: 5px; */
}

.signupDropdownLabel {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.auth-instruction {
    width: 300px;
    text-align: center;
    font-size: 14px;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
      
  }
  
  .eye-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 0.5rem;
    cursor: pointer;
  }
  