.Value {
    width: 100%;
}

.value-set-compass {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    width: 100%;
}

.value-top {
    width: 100%;
}

.value-edit-icon {
    margin-left: auto;
    margin-right: 5px;
}

.value-text {
    display: flex;
    flex-direction: row;
    /* Eventually this will be color coded based on the value category */
    background-color: var(--primaryColor);
    padding: 5px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.value-text>span:nth-of-type(1) {
    /* border: 1px solid red; */
}

.value-text>span:nth-of-type(2) {
    margin-left: auto;
    margin-right: 0px;
}

.value-text>span:nth-of-type(2)>img {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 0px;
}

.value-compass-icon {
    margin-right: 5px;
    margin-left: auto;
}

.value-expound {
    background-color: var(--primaryLite);
    padding: 5px;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
