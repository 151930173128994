/* .iv-item-container {
    background-color: var(--secondaryColor);
    border-radius: 0.5rem;
    gap: 1rem;
} */

.dv-references {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.dv-reference {
    gap: 0.5rem;
    width: 100%;
}

.dv-relations {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 10px;
}

.dv-value-dropdown {
    width: 275px;
    height: 30px;
    border-radius: 0.25rem;
    background-color: var(--primaryColor);
    display: flex;
    flex-wrap: wrap;
}

.dv-value-dropdown option {
    background-color: var(--primaryColor);
    display: flex;
    flex-wrap: wrap;
}   

/* When I set the style inside the div tag to display: "none"
    it wasn't working - it wasn registering the initial value of none, 
    and changing it to "block" properly, but nothing was displaying */
/* .dv-triggered-value {
    display: "none";
} */

.dv-triggered-value-container {
    
}

.dv-triggered-value {
    background-color: var(--conflictRed);
    border-radius: 0.5rem;
    max-width: 275px;
    padding: 5px;
}

.dv-references-container {
    padding: 5px;
}

.dv-sources-container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.dv-bottom-bar {
    /* display: flex;
    justify-items: left; */
    padding: 5px;
}

.dv-group-img-container>img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.font-lg{
    font-size: 24px;
}
.font-md{
    font-size: 22px;
}
.pointText{
    font-size: 14px;
}
.header-tip{
    justify-content: space-between;
    cursor: pointer;
    /* height: 30px; */
}
.datum-feedback{
    /* display: flex; */
    border-radius: 0.5rem;
    /* background-color: rgb(215, 215, 250); */
    margin: 1em;
    /* margin: 0.5em; */
    
}
.datum-feedback-item{
    margin: 0.5em;
    padding: 0.5em;
    /* background-color: #84e3ad; */
    border-radius: 0.8em;
    cursor: pointer;
}
.datum-feedback-item-view{   
    /* background-color: #84e3ad; */
    border-radius: 0.8em;
    cursor: pointer;
}
.datum-feedback-click{
    background-color: #84e3ad;
}
.df-bg{
    background-color: #bee0fd  !important
}

.demo-logo-vertical {
    height: 32px;
    background: #ffffff; /* Light background for logo */
    margin: 16px;
}

.ant-layout-sider {
    background: #f0f2f5; /* Light background for Sider */
}

.ant-menu {
    background: #f0f2f5; /* Light background for Menu */
    border-right: 0; /* Remove border */
}

.ant-layout-header {
    background: #ffffff; /* Light background for Header */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.ant-layout-content {
    background: #ffffff; /* Light background for Content */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}
.feedback-icon{
    border-radius: 6px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    /* margin: 1px; */
}
.feedback-tool-header{
    align-self: center;
}