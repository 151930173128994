.nf-title{
    width: 100%;
    font-size:larger;
    padding: 1em;
}
.nf-div{
    padding: 1em;
}
.nf-txt{
    width: 100%;
    height: 100%;
    min-height: 300px;
    font-size:larger;
    padding: 1em;
}
.btn-div{
    display: flex;    
    justify-content: center;
    gap: 2em;
}
.warning-input {
    border: 1px solid red; /* Highlight the input with a red border */
}

.warning-text {
    color: red; /* Color of the warning message */
    font-size: 12px; /* Smaller font size for warning */
}
@media (max-width: 1000px) {
    .tab-label {
        display: none; /* Hide the text */
    }
}

@media (min-width: 1000px) {
    .icon-a {
        padding-left: 17px;
    }

    .icon-b {
        margin-top: 4px;
        position: absolute;
    }
}
