.sheet-title-container {
  display: flex;
  font-size: 1.25rem;
  font-weight: 600;
  width: 30%;
  gap: 1rem;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
}
