.LandingInvolved {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-left: 10px;
    margin-right: 10px;
}

.landing-buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.landing-signupButton {
    width: 200px;
    height: 40px;
    font-size: 16px;
    
}

.landing-involvedText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 1rem;
}

.landing-involved-li {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    margin-top: 10px;
}

.landing-involved-innercard {
    background-color: var(--primaryUltraLite);
    border-radius: 0.25rem;
    padding: 1rem;
}